import styled from "styled-components";
import tw from "tailwind.macro";

export const Skills = styled.div`
  ${tw`flex flex-wrap w-full`};
`;

export const Skill = styled.div`
  ${tw`sm:w-1/2 ml-auto mr-auto px-2 mb-4 lg:w-1/5 `};
`;
